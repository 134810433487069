import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Button = props => {
    const isLink = props.isLink || false;
    const btnClass = `r-button ${props.isLoading ? 'loading' : ''} ${
        props.type
    } ${(props.classes || []).join(' ')}`;

    return (
        <span className={`r-button-wrapper ${props.type}`}>
            {props.isLoading ? (
                <div className="r-spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            ) : null}
            {isLink ? (
                <a className={btnClass} href={props.href}>
                    {props.children}
                </a>
            ) : (
                <button
                    className={btnClass}
                    onClick={props.isLoading ? () => {} : props.onClick}
                    name={props.name}
                    data-testid={props.dataTestid}
                >
                    {props.children}
                </button>
            )}
        </span>
    );
};

Button.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    onClick: function (props, propName, componentName) {
        if (!props.isLink && !props[propName]) {
            return new Error(
                `For isLink false onClick prop is Required ${componentName}`,
            );
        }
    },
    isLoading: PropTypes.bool,
    isLink: PropTypes.bool,
    href: function (props, propName, componentName) {
        if (props.isLink && !props[propName]) {
            return new Error(
                `For isLink true href prop is Required ${componentName}`,
            );
        }
    },
    dataTestid: PropTypes.string,
};
