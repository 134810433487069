import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";

import { Overlay } from "../Overlay";

import './styles.scss';
import { endpoints } from "../../../constants/endpoints";

const products = [
    [
        {
            title: ["Premium", "Posting"],
            icon: "/static/images/postings-icon.png",
            link: "/offerings/?productHash=4&ref=premium_posting_sidepanel"
        },
        {
            title: ["Search", "Resume"],
            icon: "/static/images/search-icon.png",
            link: "/offerings/?productHash=1&ref=search_resume_sidepanel"
        },
        {
            title: ["Calculus - Salary", " Benchmarking"],
            icon: "/static/images/calculus-icon.png",
            link: "/offerings/?productHash=2&ref=calculus_sidepanel"
        },
        {
            title: ["Employer", "Branding"],
            icon: "/static/images/side_menu_employee_branding.svg",
            link: "/offerings/?productHash=5&ref=employer_branding_sidepanel"
        },
        {
            title: ["Diversity", "Hiring"],
            icon: "/static/images/side_menu_diversity.svg",
            link: "/offerings/?productHash=3&ref=maven_diversity_sidepanel"
        }
    ],
    // [
    //     {
    //         title: ["Employer", "Branding"],
    //         icon: "/static/images/employer-branding-icon.png"
    //     },
    //     {
    //         title: ["Diversity"],
    //         icon: "/static/images/diversity-icon.png"
    //     },
    //     {
    //         title: ["Career Site"],
    //         icon: "/static/images/careersite_icon.png"
    //     }
    // ]
];

const banners = [
    {
        image: "/static/images/play-store.png",
        videoLink: "https://www.youtube.com/embed/loK_uY66iqQ",
        title: "Employer Branding in 2 mins",
        subtitle: "Understand the importance of owning your story!",
        helpText: "Want to know how we can help?",
        ctaText: "Request Demo",
        ctaLink: "/"
    },

    // {
    //     image: "/static/images/play-store.png",
    //     title: "Employer Branding in 2 mins",
    //     subtitle: "Understand the importance of owning your story!",
    //     helpText: "Want to know how we can help?",
    //     ctaText: "Request Demo",
    //     ctaLink: "/"
    // }
]

export const SideMenu = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [openClass, setOpenClass] = useState('');
    const [successMsg, setSuccessMsg] = useState({});
    const [margin, setMargin] = useState(75);

    const wait = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const toggle = async (e, msg) => {

        if (isOpen) {

            setTimeout(() => {
                if (!isOpen) {
                    setOpenClass('open');
                } else {
                    setOpenClass('');
                }
            }, 0);

            await wait(300);
        }

        setOpen((isOpen) => {
            return !isOpen
        });

        if (!isOpen) {
            setTimeout(() => {
                if (!isOpen) {
                    setOpenClass('open');
                } else {
                    setOpenClass('');
                }
            }, 0);
        }
    };

    const mouseDown = useCallback((e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    });

    useEffect(() => {
        
        if (document.getElementsByClassName("header")[0]) {
            const {top, height} = document.getElementsByClassName("header")[0].getBoundingClientRect();
            console.log(top, height);
            setMargin(top + height);
        }

        let scrollWidth = window.innerWidth - document.body.clientWidth;

        document.body.style = isOpen ? `overflow:hidden;padding-right:${scrollWidth}px` : 'overflow:auto;padding-right:0px';
        return () => {
            document.body.style = 'overflow:auto;padding-right:0px';
        };
        
    }, [isOpen]);

    const itemClick = (item) => {
        window.open(item.link, "_blank");
    }

    const bannerClick = (item, i) => {
        console.log("Clicked ..", i);
        setSuccessMsg(successMsg => {
            return { ...successMsg, [i]: true };
        })
    }

    if (window.innerWidth < 768) return <div></div>;

    return (
        <div className="side-menu">
            <span className="side-menu-toggle" tabIndex="0" aria-label="product offerings side menu" onClick={toggle} onMouseDown={mouseDown}>
                <div className="side-menu-toggle-icon-1">
                    {/* <img className="menu-new-tag" src="/static/images/new-tag.png" /> */}
                    <div className="menu-new-tag">Explore</div>
                    <img className="toggle-icon" src="/static/images/side-menu-toggle-1.png" alt = "view product offering side menu"/>
                </div>
                {/* <div className="side-menu-text">
                    All Things Talent
                    <img className="side-menu-down-arrow" src="/static/images/down-arrow.png" />
                </div> */}
            </span>
            {
                isOpen ?
                    <Overlay type={`${openClass}-overlay`} style={{ marginTop: `${margin}px` }} outsideClick={toggle}>
                        <div className={`side-menu-panel ${openClass}`} style={{ top: `${margin}px`, height: `calc(100% - ${margin}px)` }}>
                            <div className="side-menu-content">
                                <img className="side-menu-close" onClick={toggle} src="/static/images/side-menu-close.png" />
                                <div className="main-heading">One Stop Solution</div>
                                <div className="sub-heading">Products for all your hiring needs</div>
                                <div className="side-menu-products">
                                    {
                                        products.map((item, i) => {
                                            return (
                                                <div className="side-menu-products-row" key={i}>
                                                    {
                                                        item.map((product, j) => {
                                                            return (
                                                                <div className="side-menu-products-item" key={j}>
                                                                    <div onClick={itemClick.bind(this, product)} className="side-menu-products-item-icon">
                                                                        <img src={product.icon} style={{ width: "24px" }} />
                                                                    </div>
                                                                    <div className="side-menu-products-item-text">
                                                                        {
                                                                            product.title.map((i, k) => <div key={k}>{i}</div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="side-menu-banner">
                                    <div className="att-heading">All Things Talent</div>
                                    {
                                        banners.map((i, x) => (
                                            <div key={x} className="side-menu-banner-card">
                                                {i.videoLink ?
                                                    <iframe
                                                        style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}
                                                        // width="284"
                                                        // height="187"
                                                        src={i.videoLink}
                                                        frameBorder="0"
                                                        allowFullScreen
                                                    ></iframe> :
                                                    <img src={i.image} className="side-menu-banner-img" />}
                                                <div className="side-menu-banner-content">
                                                    <div className="title">{i.title}</div>
                                                    <div className="sub-title">{i.subtitle}</div>
                                                    {/* {successMsg[x] ?
                                                        <div className="success-msg">
                                                            <img src="/static/images/smalltick-icon.png" />
                                                            <div>Thanks for your request! We will reach out to you soon.</div>
                                                        </div> :
                                                        <div className="cta">
                                                            <span>{i.helpText}</span><a style={{ cursor: "pointer" }} onClick={() => bannerClick(i, x)}>{i.ctaText}</a>
                                                        </div>} */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="side-menu-footer">
                                {/* <div>Want to learn more about our products?</div>
                                <a href={`${endpoints.contact}?ref=sidepanel`} target="_blank">Contact Sales</a> */}
                            </div>
                        </div>
                    </Overlay> : null
            }
        </div>
    )

}