import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NewTag } from '../../../NewTag';
import { A } from '../../../A';
import './styles.scss';
import { NestedMenuItem } from '../NestedMenuItem';

export const MenuItem = ({ item, onClick, navItemClick, currPage }) => {
    console.log(currPage)
    const [subMenu, setSubmenu] = useState(false);
    return (
        <li
            onClick={onClick}
            onMouseEnter={() => setSubmenu(true)}
            onMouseLeave={() => setSubmenu(false)}
            className="menu-list-item"
        >
            {item.isReact ? (
                <NavLink to={item.link} activeClassName="active">
                    {item.title}
                </NavLink>
            ) : (
                <A className="header-links" href={item.link} tabindex="0">
                    {item.title}
                </A>
            )}

            {item.children.length ? (
                <>
                    <i className="icon-down_arrow"></i>
                    <div
                        className={`${item.titleClassToAddMenu ? item.titleClassToAddMenu : ""} profile-menu ${subMenu ? 'active' : null}`}
                    >
                        <div className={`profile-body ${item.titleClassToAddBody ? item.titleClassToAddBody : ""}`}>
                            <ul className={`profile-item-listing ${item.titleClassToAddUl ? item.titleClassToAddUl : ""}`}>
                                {item.children.map((sublink, i) => (
                                    <React.Fragment key={i}>
                                        {sublink.isHidden ? null : (
                                            <>
                                                <li className={sublink.classToAdd}>
                                                    <A
                                                        href={`${sublink.link}${currPage ? ("_" + currPage): ""}`}
                                                        onClick={
                                                            sublink.onClick
                                                                ? () => {
                                                                    sublink.onClick(
                                                                        navItemClick,
                                                                    )();
                                                                }
                                                                : null
                                                        }
                                                        className="subMenuSpanTag"
                                                        data-testid={sublink.title}
                                                    >
                                                        {sublink.nestedChildren ? 
                                                            <NestedMenuItem
                                                                onClick={
                                                                    sublink.nestedChildren.onClick
                                                                        ? sublink.nestedChildren.onClick(
                                                                            navItemClick,
                                                                        )
                                                                        : null
                                                                }
                                                                item={sublink.nestedChildren}
                                                                navItemClick={
                                                                    navItemClick
                                                                }
                                                            />: 
                                                            sublink.title}
                                                    </A>
                                                    {sublink.isNew ? (
                                                        <NewTag
                                                            className="sublink-new-tag"
                                                            withoutArrow
                                                        />
                                                    ) : null}
                                                    { sublink.subtitle && 
                                                    <p>{sublink.subtitle}</p>}
                                                </li>
                                               
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* ) : null} */}
                </>
            ) : null}
            {item.isNew ? <NewTag /> : null}
        </li>
    );
};
